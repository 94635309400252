import React from "react"
import styled from "styled-components"
import Seo from "components/controller/seo"

const Wrapper = styled.div`
  min-height: 70vh;
  width: 100%;
  color: black;
  background: ${props => props.theme.colors.yellow};
  font-family: "Arimo", sans-serif;
  text-align: center;

  padding: 50px 0 50px;
  @media (min-width: 700px) {
    padding: 50px 0 50px;
  }
`

const H1 = styled.h1`
  text-align: center;
  font-family: "Merriweather", serif;
  font-weight: bold;
  font-size: 48px;
`

const NotFound = () => (
  <Wrapper>
    <Seo title="404: Not found" />
    <H1>NOT FOUND</H1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </Wrapper>
)

export default NotFound
